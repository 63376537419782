const defaultRoutes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/home/IndexPage.vue') }
    ]
  },
  {
    path: '/signup',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/signup/IndexPage.vue') }
    ]
  },
  {
    path: '/login',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/login/IndexPage.vue') }
    ]
  },
  {
    path: '/forget-password',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/forget-password/IndexPage.vue')
      }
    ]
  },
  {
    path: '/reset-password',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/reset-password/IndexPage.vue')
      }
    ]
  },
  {
    path: '/pricing',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/pricing/IndexPage.vue') }
    ]
  },
  {
    path: '/result',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/payment/RedirectPage.vue') }
    ]
  },
  {
    path: '/download',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/download/IndexPage.vue') }
    ]
  },
  {
    path: '/invite/:code(\\d{6})',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/invite/IndexPage.vue') }
    ]
  },
  {
    path: '/ios-download-tutorial',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/ios-download-tutorial/IndexPage.vue')
      },
      {
        path: 'new',
        component: () =>
          import('src/pages/ios-download-tutorial/NewAppAccPage.vue')
      },
      {
        path: 'change',
        component: () =>
          import('src/pages/ios-download-tutorial/ChangeAppAccPage.vue')
      }
    ]
  },
  {
    path: '/promote',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/promote/IndexPage.vue') }
    ]
  },
  {
    path: '/help/:path*',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/help/IndexPage.vue'),
        props: { origin: 'https://goduck.tawk.help' }
      }
    ]
  },
  {
    path: '/faq',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/help/IndexPage.vue'),
        props: { url: 'https://goduck.tawk.help/category/faq' }
      }
    ]
  },
  {
    path: '/announcement',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/help/IndexPage.vue'),
        props: { url: 'https://goduck.tawk.help/category/%E5%85%AC%E5%91%8A' }
      }
    ]
  },
  {
    path: '/blog',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/wip/IndexPage.vue')
      }
    ]
  },
  {
    path: '/tutorial',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/help/IndexPage.vue'),
        props: { url: 'https://goduck.tawk.help/category/%E5%85%AC%E5%91%8A' }
      }
    ]
  },
  {
    path: '/bind-email',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/bind-email/IndexPage.vue')
      }
    ]
  },
  {
    path: '/change-password',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/change-password/IndexPage.vue')
      }
    ]
  },
  {
    path: '/my-account',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/my-account/IndexPage.vue') }
    ]
  },
  {
    path: '/my-affiliate-program',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/my-affiliate-program/IndexPage.vue')
      }
    ]
  },
  {
    path: '/my-commission-records',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/my-commission-records/IndexPage.vue')
      }
    ]
  },
  {
    path: '/my-invitation-records',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/my-invitation-records/IndexPage.vue')
      }
    ]
  },
  {
    path: '/my-payment-records',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/my-payment-records/IndexPage.vue')
      }
    ]
  },
  {
    path: '/ndweDfsWioOIJSNusind',
    component: () => import('layouts/EmptyLayout.vue'),
    children: [
      { path: '', component: () => import('pages/chat/IndexPage.vue') }
    ]
  },
  {
    path: '/agr',
    component: () => import('layouts/EmptyLayout.vue'),
    children: [
      { path: '', component: () => import('pages/agreement/IndexPage.vue') }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/home/IndexPage.vue') }
    ]
  }
]

export const constantRoutes = []
export const notFoundRoutes = []
export default defaultRoutes
