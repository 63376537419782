import { useUserStore } from 'stores/user'
import { base64Encode, url } from 'src/utils/util'

/**
 * @param {Object} options
 * @param {import('vue-router').Router} options.router
 * @param {import('pinia').Pinia} options.store
 */
export default function ({ router, store }) {
  const userStore = useUserStore(store)

  router.beforeEach(async (to, from, next) => {
    // TODO offline $q.notify
    // if (!process.env.SERVER && !navigator?.onLine) {
    //   $q.notify({
    //     type: 'negative',
    //     message: 'Please check your connection',
    //     position: 'top'
    //   })
    //   return next(false)
    // }

    const matchedPath = to.matched.slice(-1)[0].path ?? to.path

    // token navigation guard
    switch (matchedPath) {
      case '/bind-email':
      case '/change-password':
      case to.path === '/help/article/freevpn' && matchedPath:
      case '/my-account':
      case '/my-affiliate-program':
      case '/my-commission-records':
      case '/my-invitation-records':
      case '/my-payment-records':
      case '/result':
        userStore.token
          ? next()
          : next({
              path: '/login',
              query: {
                lang: userStore.lang,
                redirect: base64Encode(
                  // 從登入成功轉址中，拿掉自動登入參數
                  url(to.fullPath, {
                    base: '/',
                    query: { usr: null, goduck: null, d: null, t: null }
                  })
                ),
                // 從 app 來的自動登入參數
                usr: to.query.usr,
                goduck: to.query.goduck,
                d: to.query.d,
                t: to.query.t
              }
            })
        return
      case '/signup':
      case '/login':
      case '/forget-password':
      case to.query.token && to.query.username && '/reset-password':
        userStore.token
          ? next({ path: '/', query: { lang: userStore.lang } })
          : next()
        return
      case '/':
      case '/help/:path*':
      case '/announcement':
      case '/faq':
      case '/blog':
      case '/tutorial':
      case '/download':
      case '/pricing':
      case '/promote':
      case '/agr':
      case '/ndweDfsWioOIJSNusind':
      case '/invite/:code(\\d{6})':
      case '/ios-download-tutorial':
      case '/ios-download-tutorial/new':
      case '/ios-download-tutorial/change':
        next()
        return
      default:
        next({ path: '/', query: { lang: userStore.lang } })
        return
    }
  })
}
